import './App.css';
import { Button, Switch, ThemeProvider, createTheme } from '@mui/material';
import { IoLogoLinkedin, IoMdMail} from "react-icons/io";
import {SiCplusplus, SiPython, SiPostgresql, SiPlaywright, SiFlask, SiDocker, SiJavascript, SiReact, SiGnubash, SiGit, SiHtml5, SiCss3, SiLinux, SiTypescript, SiGraphql, SiJest} from "react-icons/si";
import projects from './projects.json';
import React, { useState } from 'react';

export const ThemeContext = React.createContext(null);

function App() {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () =>{
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  }
  
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: "#1493DC",
      }
    }
  })
  return(
    <ThemeContext.Provider value={{theme, toggleTheme}}>
      <section id={theme}>
        <div class='website' >
          <div class="landing">
            <div class="start">
              <nav>
                <img src={`assets/logo.png`} alt="logo" class='logo'/>
                <ThemeProvider theme={muiTheme}>
                  <Switch id="button" onChange={toggleTheme}/>
                </ThemeProvider>
              </nav>
            </div>
            <div class="introContainer">
              <div class="intro">
                <figure class='pic'>
                  <img src={`/assets/doodle.png`} alt="headshot" class='headshot'/>
                </figure>
                <div class='main_text'>
                  <h1 class='title'>Hi, I’m <span>Andy</span>!</h1>
                  <h1 class="tagline">I’m a software developer studying computer science and business administration at the <span class="low-highlight">University of Michigan</span></h1>
                  <div class='contactContainer'>
                    <div class='contact'>
                      <a href='https://www.linkedin.com/in/andyky/' target='_blank' rel="noreferrer" class='icon'><IoLogoLinkedin size="75px"/></a>
                      <a href='mailto:andyky@umich.edu' target='_blank' rel="noreferrer" class='icon'><IoMdMail size="75px"/></a>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <h1 class='titleproject'> Projects</h1>
          <div class="projects">
            {projects.map((project, index) => (
              <div className="container" key={index}>
                <div className='imgcontainer'>
                  <img src={project.image} class='projectimg' alt='projectimage'/>
                </div>
                <div class="textcontainer">
                  <h1>{project.title}</h1>
                  <h2>{project.subtitle}</h2>
                  <p>{project.description}</p>
                  {project.buttonText && <ThemeProvider theme={muiTheme}>
                    <Button variant="contained" color="primary" href={project.link} target="_blank">{project.buttonText}</Button>
                  </ThemeProvider>}
                </div>
              </div>
            ))}
          </div>
          <h1 class='titleproject'>Toolkit</h1>
          <div class="projects"> 
            <div class="toolcontainer">
              <div class="toolrow"> 
                <SiCplusplus size='50px' class='icon'></SiCplusplus>
                <SiPython size='50px' class='icon'></SiPython>
                <SiJavascript size='50px' class='icon'></SiJavascript>
                <SiTypescript size='50px' class='icon'></SiTypescript>
              </div>
              <div class="toolrow">
                <SiHtml5 size='50px' class='icon'></SiHtml5>
                <SiCss3 size='50px' class='icon'></SiCss3>
                <SiReact size='50px' class='icon'></SiReact>
                <SiFlask size='50px' class='icon'></SiFlask>
              </div>
              <div class="toolrow">
                <SiGnubash size='50px' class='icon'></SiGnubash>
                <SiGit size='50px' class='icon'></SiGit>
                <SiLinux size='50px' class='icon'></SiLinux>
                <SiDocker size='50px' class='icon'></SiDocker>
              </div>
              <div class="toolrow">
                <SiPostgresql size='50px' class='icon'></SiPostgresql>
                <SiGraphql size='50px' class='icon'></SiGraphql>
                <SiJest size='50px' class='icon'></SiJest>
                <SiPlaywright size='50px' class='icon'></SiPlaywright>
              </div>
            </div>
          </div>
          <div class='footer'>
            <a href='https://www.linkedin.com/in/andyky/' target='_blank' rel="noreferrer" class='icon'><IoLogoLinkedin size="75px"/></a>
            <a href='mailto:andyky@umich.edu' target='_blank' rel="noreferrer" class='icon'><IoMdMail size="75px"/></a>
          </div>
        </div>
      </section>
    </ThemeContext.Provider>
  )
}

export default App;
